import React, { useState } from "react";
import Box from "./box";
import { useEffect } from "react";

interface Props {
  alphabet: string;
  squareSize: number;
  pressedKey: string;
}

interface Coordinates {
  x: number;
  y: number;
}

const Square: React.FC<Props> = ({ alphabet, squareSize, pressedKey }) => {
  const [location, setLocation] = useState<Coordinates | null>(null);

  useEffect(() => {
    const match = () => {
      const searchKey = pressedKey.toUpperCase();

      for (let x = 0; x < square.length; x++) {
        for (let y = 0; y < square[x].length; y++) {
          if (square[x][y] === searchKey) {
            setLocation({ x, y });
            return { x, y };
          }
        }
      }
      setLocation(null);
    };
    match();
  }, [pressedKey, squareSize, alphabet]);

  const generateSquare = () => {
    let index = 0;
    const square: string[][] = [];

    for (let i = 0; i < squareSize; i++) {
      square.push([]);
    }

    for (let x = 0; x < squareSize; x++) {
      for (let y = 0; y < squareSize; y++) {
        square[x][y] = alphabet[index];
        index++;
      }
    }
    return square;
  };

  const square = generateSquare();

  // Render the Polybius square
  return (
    <div>
      <div className="flex flex-row justify-center gap-1 mb-1">
        <Box type="blank" />
        {Array.from({ length: squareSize }, (_, index) => (
          <Box key={index} type="number">
            {index + 1}
          </Box>
        ))}
      </div>
      <div className="grid grid-cols-1 gap-1">
        {square.map((row, rowIndex) => (
          <div key={rowIndex} className="flex flex-row gap-1 justify-center">
            <Box key={rowIndex} type="number">
              {rowIndex + 1}
            </Box>

            {row.map((letter, colIndex) => (
              <Box
                key={`letter-${rowIndex}-${colIndex}`}
                type="letter"
                highlight={
                  location && location.x === rowIndex && location.y === colIndex
                }
              >
                {letter}
              </Box>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Square;
