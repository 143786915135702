import Header from "./header";
import BaseContainer from "./base-container";
import Navigation from "./navigation";
import Footer from "./footer";

const Layout = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <div className="grow">
        <Header>
          <Navigation />
        </Header>
        <BaseContainer />
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
