const navItems = [
  {
    "id": 1,
    "name": "Encryption",
    "path": "/encryption"
  },
  {
    "id": 2,
    "name": "Decryption",
    "path": "/decryption"
  },
  {
    "id": 3,
    "name": "About Polybius",
    "path": "/about-polybius"
  }
];

export default navItems;
