import { FormEvent, useEffect, useState } from "react";
import SoundGenerator from "./sound-generator";
import Button from "../common/button";
import CopyToClipboard from "../common/copy-to-clipboard";
import Square from "./square";
import SendMail from "../mail/send-mail";
import { useRef } from "react";
type Alphabet = {
  id: string;
  alphabet: string;
  squareSize: number;
};
const EncryptionMechanism = () => {
  const alphabet: Alphabet[] = [
    { id: "Greek", alphabet: "ΑΒΓΔΕΖΗΘΙΚΛΜΝΞΟΠΡΣΤΥΦΧΨΩ", squareSize: 5 },
    { id: "English", alphabet: "ABCDEFGHIJKLMNOPQRSTUVWXYZ", squareSize: 6 },
  ];

  const options = alphabet.map((a, index) => (
    <option key={index} value={a.id}>
      {a.id}
    </option>
  ));

  const [selectedAlphabet, setSelectedAlphabet] = useState<Alphabet>({
    id: "",
    alphabet: "",
    squareSize: 0,
  });
  const [phrase, setPhrase] = useState("");
  const [hasChange, setHasChange] = useState(false);
  const [language, setLanguage] = useState("Greek");
  const [encrypted, setEncrypted] = useState<
    { x: number; y: number; index: number }[]
  >([]);
  const [pressedKey, setPressedKey] = useState("");
  const [isSendOpen, setIsSendOpen] = useState(false);
  const [shouldScroll, setShouldScroll] = useState(false);

  const handleSelect = (event: { target: { value: string } }) => {
    setLanguage(event.target.value);
    if (event.target.value === "Select language") {
      setLanguage("");
    } else {
      const selectedAlphabetObject = alphabet.find(
        (a) => a.id === event.target.value
      );
      setSelectedAlphabet(selectedAlphabetObject || alphabet[0]);
    }
    setHasChange(true);
  };

  const handleInput = (event: { target: { value: string } }) => {
    setPhrase(event.target.value.toUpperCase());
    setHasChange(true);
  };

  let index = 0;

  const findMatch = (square: string[][], phrase: string[]) => {
    const coordinates = [];
    for (let x = 0; x < square.length; x++) {
      for (let y = 0; y < square[x].length; y++) {
        const squareChar = square[x][y];
        let index = phrase.indexOf(squareChar);

        while (index !== -1) {
          coordinates.push({ x: x + 1, y: y + 1, index });
          index = phrase.indexOf(squareChar, index + 1);
        }
      }
    }
    return coordinates;
  };

  const sortEncryption = (
    encryption: {
      x: number;
      y: number;
      index: number;
    }[]
  ) => {
    return encryption.sort((a, b) => a.index - b.index);
  };

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const normalizedPhrase = phrase
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
    const phraseToArray = normalizedPhrase.split("");

    const normalizedAlphabet = selectedAlphabet.alphabet
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
    const alphabetSet = new Set(normalizedAlphabet.split(""));
    const isValidPhrase = phraseToArray.every(
      (char) => char === " " || alphabetSet.has(char)
    );
    if (!isValidPhrase) {
      alert(
        "The phrase contains characters not in the selected language's alphabet."
      );
      return;
    }

    let square: string[][] = [];
    for (let i = 0; i < selectedAlphabet.squareSize; i++) {
      square.push([]);
    }
    for (let x = 0; x < selectedAlphabet.squareSize; x++) {
      for (let y = 0; y < selectedAlphabet.squareSize; y++) {
        square[x][y] = selectedAlphabet.alphabet[index];
        index++;
      }
    }

    const encrypted = findMatch(square, phraseToArray);
    sortEncryption(encrypted);
    setEncrypted(encrypted);
    setPressedKey("");
  };

  const handleClear = (event: React.MouseEvent<HTMLButtonElement>) => {
    setSelectedAlphabet({
      id: "",
      alphabet: "",
      squareSize: 0,
    });
    setLanguage("");
    setPhrase("");
    setEncrypted([]);
    setHasChange(false);
    const selectElement = document.getElementById(
      "alphabet"
    ) as HTMLSelectElement;
    selectElement.selectedIndex = 0;
    setPressedKey("");
    setIsSendOpen(false);
  };

  const handleCopy = () => {
    CopyToClipboard("outcome");
  };

  const handleSend = () => {
    setIsSendOpen(!isSendOpen);
    setShouldScroll(true);
  };
  useEffect(() => {
    if (isSendOpen && shouldScroll) {
      const e = document.getElementById("sendToFriend");
      e?.scrollIntoView({
        behavior: "smooth",
      });
      setShouldScroll(false);
    }
  }, [isSendOpen, shouldScroll]);

  const handleKeyDown = (event: any) => {
    if (event.key) {
      setPressedKey(event.key);
    }
  };

  const outcome = encrypted.map((coord) => Number(`${coord.x}${coord.y}`));

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-row gap-6">
        <div className="w-full">
          <form onSubmit={onSubmit} className="flex flex-col gap-3 items-start">
            <div className=" ">
              <select
                className="border border-gray-500 p-2 w-full rounded-lg"
                id="alphabet"
                onChange={handleSelect}
              >
                <option>Select language</option>
                {options}
              </select>
            </div>
            <div className="w-full">
              <textarea
                id="phrase"
                autoComplete="off"
                value={phrase}
                rows={3}
                placeholder="Enter the phrase you want to encrypt"
                className="border border-gray-500 p-2 w-full rounded-lg"
                onKeyDown={handleKeyDown}
                onChange={handleInput}
              />
            </div>
            <div className="flex flex-row justify-end gap-2">
              <Button
                type="reset"
                disabled={!hasChange}
                kind="outlined"
                onClick={handleClear}
              >
                Clear
              </Button>
              <Button type="submit" kind="contained">
                Encrypt
              </Button>
            </div>
          </form>
          {encrypted.length !== 0 && (
            <>
              <div className="w-full mt-8 rounded-lg p-4 bg-slate-100">
                <h2 className="font-semibold mb-2 flex bg-slate-200 p-2 rounded-md flex-col sm:flex-row sm:justify-between items-start sm:items-end">
                  <span>Your encrypted message</span>{" "}
                  <div className="flex flex-row gap-2">
                    <SoundGenerator encryptions={outcome} />
                    <Button kind="outlined" type="button" onClick={handleSend}>
                      <img
                        className="w-3"
                        src="./assets/paper-plane-regular.svg"
                        alt="copy"
                        title="Send to friend"
                      />
                    </Button>
                    <Button kind="outlined" type="button" onClick={handleCopy}>
                      <img
                        className="w-3"
                        src="./assets/copy-regular.svg"
                        alt="copy"
                        title="Copy to clipboard"
                      />
                    </Button>
                  </div>
                </h2>
                <div className="text-xl p-2" id="outcome">
                  {encrypted.map((coord, index) => (
                    <span key={index}>{`${coord.x}${coord.y}${" "}`}</span>
                  ))}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="w-full hidden">
        {selectedAlphabet.alphabet ? (
          <Square
            alphabet={selectedAlphabet.alphabet}
            squareSize={selectedAlphabet.squareSize}
            pressedKey={pressedKey}
          />
        ) : (
          <div className="bg-slate-100 rounded-lg w-full h-full flex justify-center items-center text-slate-700">
            Select alphabet to display the appropriate square.
          </div>
        )}
      </div>
      {isSendOpen && (
        <div
          id="sendToFriend"
          className=" w-full sm:w-80 self-start items-start"
        >
          <h2 className="font-bold mb-2">Send your message to a friend</h2>
          <SendMail language={selectedAlphabet.id} message={outcome} />
        </div>
      )}
    </div>
  );
};

export default EncryptionMechanism;
