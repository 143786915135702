import { useEffect, useState } from "react";
import randomNumber from "../utils/randomNumber";
import Button from "../common/button";

type Props = {
  encryptions: number[];
};
const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0];
const newRandom = randomNumber(numbers);

const SoundGenerator = ({ encryptions }: Props) => {
  const [audioContext, setAudioContext] = useState<AudioContext | null>(null);
  const [oscillator, setOscillator] = useState<OscillatorNode | null>(null);
  const [activeOscillators, setActiveOscillators] = useState<OscillatorNode[]>(
    []
  );
  const [activeOscillatorsGain, setActiveOscillatorsGain] = useState<
    GainNode[]
  >([]);
  const [oscillatorGain, setOscillatorGain] = useState<GainNode | null>(null);
  const [startTime, setStartTime] = useState(0);
  const [stopTime, setStopTime] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);

  const frequencies = encryptions.map((e) => Number(`${e}${newRandom}`));
  // console.log(frequencies);

  useEffect(() => {
    const context = new AudioContext();
    setAudioContext(context);
    return () => {
      context.close();
    };
  }, []);

  const handlePlay = () => {
    if (audioContext && audioContext?.state === "suspended") {
      audioContext.resume();
    }

    if (audioContext && !oscillator) {
      setIsPlaying(true);
      let startTime = 0;
      const noteLength = 800;

      frequencies.forEach((freq, index) => {
        const osci = audioContext.createOscillator();
        const osciGain = audioContext.createGain();
        osci.frequency.value = freq;
        osci.connect(osciGain);
        osciGain.connect(audioContext.destination);

        const startAt = audioContext.currentTime + startTime / 1000;
        const gainRampStart = startAt + (noteLength - 100) / 1000;
        const stopAt = startAt + noteLength / 1000;

        osci.start(startAt);
        osciGain.gain.setValueAtTime(1, startAt);
        osciGain.gain.exponentialRampToValueAtTime(0.001, gainRampStart + 0.8);
        osci.stop(stopAt);
        startTime += noteLength;

        activeOscillators.push(osci);
        activeOscillatorsGain.push(osciGain);

        osci.onended = () => {
          if (index === frequencies.length - 1) {
            setIsPlaying(false);
          }
        };
      });
    }
  };

  const handleStop = () => {
    if (audioContext?.state === "running") {
      activeOscillators.forEach((activeOsci) => {
        activeOsci.stop();
        activeOsci.disconnect();
      });
      activeOscillatorsGain.forEach((activeOsciGain) => {
        activeOsciGain.disconnect();
      });
      setActiveOscillators([]);
      setActiveOscillatorsGain([]);
      if (audioContext) {
        audioContext.suspend();
      }
    }
    setIsPlaying(false);
  };

  return (
    <div className="flex flex-row gap-2">
      <Button
        kind="outlined"
        disabled={!isPlaying}
        type="button"
        onClick={handleStop}
      >
        <img
          className="w-2.5"
          src="./assets/stop-solid.svg"
          alt="stop"
          title="Play me"
        />
      </Button>
      <Button
        kind="outlined"
        type="button"
        disabled={isPlaying}
        onClick={handlePlay}
      >
        <img
          className="w-2.5"
          src="./assets/play-solid.svg"
          alt="play"
          title="Play me"
        />
      </Button>
    </div>
  );
};

export default SoundGenerator;
