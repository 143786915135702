const Footer = () => {
  return (
    <div className="w-full text-center bg-slate-50  p-4">
      <span className="text-slate-500 text-xs">
        Ver.: {process.env.REACT_APP_VERSION}
      </span>
    </div>
  );
};

export default Footer;
