import EncryptionMechanism from "../components/encryption";
import PageTitle from "../common/page-title";
import Container from "../layout/container";

const Encryption = () => {
  return (
    <>
      <PageTitle title="Encryption" />
      <div className="mb-6">Encrypt a message using Polibius' method</div>
      <EncryptionMechanism />
    </>
  );
};

export default Encryption;
