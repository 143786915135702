import { FormEvent, useState, useEffect } from "react";
import Button from "../common/button";
import CopyToClipboard from "../common/copy-to-clipboard";
type Alphabet = {
  id: string;
  alphabet: string;
  squareSize: number;
};
const DecryptionMechanism = () => {
  const alphabet: Alphabet[] = [
    { id: "Greek", alphabet: "ΑΒΓΔΕΖΗΘΙΚΛΜΝΞΟΠΡΣΤΥΦΧΨΩ", squareSize: 5 },
    { id: "English", alphabet: "ABCDEFGHIJKLMNOPQRSTUVWXYZ", squareSize: 6 },
  ];

  const options = alphabet.map((a, index) => (
    <option key={index} value={a.id}>
      {a.id}
    </option>
  ));

  const [selectedAlphabet, setSelectedAlphabet] = useState<Alphabet>({
    id: "",
    alphabet: "",
    squareSize: 0,
  });
  const [message, setMessage] = useState("");
  const [hasChange, setHasChange] = useState(false);
  const [language, setLanguage] = useState("");
  const [decrypted, setDecrypted] = useState<string>("");
  const [square, setSquare] = useState<string[][]>([]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const messageFromParam = params.get("message");
    const langFromParam = params.get("lang");

    if (messageFromParam) {
      setMessage(decodeURIComponent(messageFromParam));
    }
    if (langFromParam) {
      setLanguage(decodeURIComponent(langFromParam));
    }
  }, []);

  useEffect(() => {
    if (language) {
      const selectedAlphabetObject = alphabet.find((a) => a.id === language);
      if (selectedAlphabetObject) {
        setSelectedAlphabet(selectedAlphabetObject);

        const newSquare: string[][] = [];
        let index = 0;
        for (let i = 0; i < selectedAlphabetObject.squareSize; i++) {
          newSquare.push([]);
        }
        for (let x = 0; x < selectedAlphabetObject.squareSize; x++) {
          for (let y = 0; y < selectedAlphabetObject.squareSize; y++) {
            newSquare[x][y] = selectedAlphabetObject.alphabet[index];
            index++;
          }
        }
        setSquare(newSquare);
      }
    }
  }, [language]);

  const handleSelect = (event: { target: { value: string } }) => {
    setLanguage(event.target.value);
    setHasChange(true);
  };

  const handleInput = (event: { target: { value: string } }) => {
    event.target.value = event.target.value
      .replace(/[^\dA-Z]/g, "")
      .replace(/(.{2})/g, "$1 ")
      .trim();

    setMessage(event.target.value);
    setHasChange(true);
  };

  const decryptMessage = (encryptedMessage: any, square: any) => {
    const decryptedChars: any[] = [];
    encryptedMessage.forEach((pair: number[]) => {
      const x = pair[0];
      const y = pair[1];
      decryptedChars.push(square[x - 1][y - 1]);
    });
    return decryptedChars.join("");
  };

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (message === "") {
      alert("Please enter the message you want to decrypt");
      return;
    }
    if (language === "") {
      alert("Please select the language you want to decrypt to");
      return;
    }
    const encryptedPairs = message
      .split(" ")
      .map((pair) => pair.split("").map(Number));
    const decryptedMessage = decryptMessage(encryptedPairs, square);
    setDecrypted(decryptedMessage);
  };

  const handleClear = (event: React.MouseEvent<HTMLButtonElement>) => {
    setSelectedAlphabet({
      id: "",
      alphabet: "",
      squareSize: 0,
    });
    setLanguage("");
    setMessage("");
    setDecrypted("");
    setHasChange(false);
    const selectElement = document.getElementById(
      "alphabet"
    ) as HTMLSelectElement;
    selectElement.selectedIndex = 0;
  };

  const handleCopy = () => {
    CopyToClipboard("outcome");
  };
  // const outcome = encrypted.map((coord) => Number(`${coord.x}${coord.y}`));

  return (
    <div className="w-full">
      <form onSubmit={onSubmit} className="flex flex-col gap-3 items-start">
        <div>
          <select
            className="border border-gray-500 p-2 w-full rounded-lg"
            id="alphabet"
            onChange={handleSelect}
            value={language}
          >
            <option>Select language</option>
            {options}
          </select>
        </div>
        <div className="w-full">
          <textarea
            id="message"
            autoComplete="off"
            value={message}
            rows={3}
            placeholder="Enter the number sequence you want to decrypt"
            className="border border-gray-500 p-2 w-full rounded-lg"
            onChange={handleInput}
          />
        </div>

        <div className="flex flex-row justify-end gap-2">
          <Button
            type="reset"
            disabled={!hasChange}
            kind="outlined"
            onClick={handleClear}
          >
            Clear
          </Button>
          <Button type="submit" kind="contained">
            Decrypt
          </Button>
        </div>
      </form>
      {decrypted.length !== 0 && (
        <div className="w-full mt-8 mb-4 rounded-lg p-4 bg-slate-100">
          <h2 className="font-semibold mb-2 flex bg-slate-200 p-2 rounded-md flex-row justify-between items-end">
            <span>Your decrypted message</span>{" "}
            <Button kind="outlined" type="button" onClick={handleCopy}>
              <img className="w-3" src="./assets/copy-regular.svg" alt="copy" />
            </Button>{" "}
          </h2>

          <div className="text-xl p-2" id="outcome">
            {decrypted}
          </div>
        </div>
      )}
    </div>
  );
};

export default DecryptionMechanism;
