import { Outlet } from "react-router-dom";

const BaseContainer = () => {
  return (
    <div className="max-w-5xl mx-auto mt-6 p-6 mb-8">
      <Outlet />
    </div>
  );
};

export default BaseContainer;
