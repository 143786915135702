import navItems from "../data/nav";
import { useState } from "react";
import { Link } from "react-router-dom";
import Button from "../common/button";
import Logo from "../common/logo";
const Navigation = () => {
  const [sidebar, setIsSidebar] = useState(false);
  const [isActiveMenu, setIsActiveMenu] = useState<number | null>(null);
  const handleActiveMenu = (id: number) => {
    setIsActiveMenu(id);
    setIsSidebar(false);
  };

  const handleSidebar = () => {
    setIsSidebar(!sidebar);
    if (!sidebar) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  };

  return (
    <div className="w-full flex flex-row justify-end">
      {sidebar && (
        <div
          className={`fixed -inset-4 bg-black opacity-65 z-30`}
          onClick={handleSidebar}
        />
      )}
      <div className="flex-row gap-6 hidden sm:flex">
        {navItems.map((item) => (
          <div
            key={item.id}
            className={`${
              isActiveMenu === item.id && "text-sky-600"
            } font-medium`}
            onClick={() => handleActiveMenu(item.id)}
          >
            <Link to={item.path}>{item.name}</Link>
          </div>
        ))}
      </div>
      <div className="flex-row gap-6 flex sm:hidden">
        <Button
          type="button"
          kind="outlined"
          onClick={handleSidebar}
          className="px-1.5 rounded-md py-1.5 border-teal-600 bg-slate-100 relative z-50"
        >
          <img
            src={`./assets/${
              sidebar === false ? "bars-solid" : "xmark-solid"
            }.svg`}
            className=" w-4 h-4"
            alt="menu"
          />
        </Button>
        <div
          className={`w-[250px] pl-6 pt-4 bg-white shadow-lg h-full absolute top-0 z-40 transition-all duration-500 ${
            sidebar ? "left-0" : " -left-[250px]"
          }`}
        >
          <Logo />
          <hr className="bg-slate-300 -ml-6 my-4" />
          <div className="text-xl flex flex-col gap-3">
            {navItems.map((item) => (
              <div
                key={item.id}
                className={`${
                  isActiveMenu === item.id && "text-sky-600"
                } font-medium`}
                onClick={() => handleActiveMenu(item.id)}
              >
                <Link to={item.path}>{item.name}</Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navigation;
