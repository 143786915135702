import PageTitle from "../common/page-title";
import DecryptionMechanism from "../components/decryption";
import Container from "../layout/container";

const Decryption = () => {
  return (
    <>
      <PageTitle title="Decryption" />
      <div className="mb-6">Enter the message you want to decrypt</div>
      <DecryptionMechanism />
    </>
  );
};

export default Decryption;
