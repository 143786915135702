import emailjs from "@emailjs/browser";
import { useRef } from "react";
import Button from "../common/button";

type Props = {
  message: number[];
  language: string;
};

const SendMail = ({ message, language }: Props) => {
  const form = useRef<HTMLFormElement | null>(null);

  const sendEmail = (e: React.FormEvent) => {
    e.preventDefault();
    if (form.current) {
      emailjs
        .sendForm("service_zk9onkq", "template_m9d5ik8", form.current, {
          publicKey: "BVKQsNhHfeKbCsXc-",
        })
        .then(
          () => {
            // console.log("SUCCESS!");
            alert("Your message has been sent.");
          },
          (error) => {
            // console.log("FAILED...", error.text);
            alert("Your was not sent. Try again.");
          }
        );
    }
  };

  return (
    <>
      <form ref={form} onSubmit={sendEmail} className="flex flex-col gap-1">
        <div className="flex flex-col sm:flex-row gap-3">
          <div>
            <label>From</label>
            <input
              className="border border-gray-500 p-2 w-full rounded-lg"
              type="text"
              name="from_name"
              id="from_name"
              autoComplete="off"
            />
          </div>
          <div>
            <label>To</label>
            <input
              className="border border-gray-500 p-2 w-full rounded-lg"
              type="text"
              name="to_name"
              id="to_name"
              autoComplete="off"
            />
          </div>
        </div>
        <label>Email</label>
        <input
          className="border border-gray-500 p-2 w-full rounded-lg"
          type="email"
          name="user_email"
          autoComplete="off"
        />
        <input
          className="border hidden border-gray-500 p-2 w-full rounded-lg"
          type="hidden"
          value={language}
          name="message_language"
          autoComplete="off"
        />
        <label>Message</label>
        <textarea
          className="border border-gray-500 p-2 w-full rounded-lg"
          name="message"
          value={message.join(" ")}
        />
        <Button
          className="w-full sm:w-1/3 mt-2 self-end"
          kind="contained"
          type="submit"
        >
          Send
        </Button>
      </form>
    </>
  );
};

export default SendMail;
