import PageTitle from "../common/page-title";

const Home = () => {
  return (
    <>
      <PageTitle title="Polybius Square Cipher: Fun with Encryption!" />
      <div className="mb-6">
        <img
          className="rounded-lg mb-4 object-cover h-64 w-full"
          src="./assets/hero.jpg"
          alt="hero"
        />
        <p className="mb-4">Welcome to the Polybius Cipher Tool!</p>
        <p className="mb-4">
          Discover the fun of transforming your messages into encrypted puzzles
          using the classic Polybius Square method.
        </p>
        <p className="mb-4">
          Just type in your text,{" "}
          <a className="text-teal-600" href="/encryption">
            encrypt
          </a>{" "}
          it, and then challenge your friends to{" "}
          <a className="text-teal-600" href="/decryption">
            decode
          </a>{" "}
          it. Whether for amusement or to puzzle your pals, our tool makes
          encryption easy and enjoyable with just a few clicks.
        </p>
      </div>
    </>
  );
};

export default Home;
