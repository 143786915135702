type Props = {
  children?: React.ReactNode;
  type?: "number" | "letter" | "blank";
  highlight?: boolean | null;
};
const Box = ({ children, type = "letter", highlight = false }: Props) => {
  let classList =
    "w-14 h-14 rounded-md flex items-center justify-center font-medium duration-500 ";

  if (type === "number") {
    classList += " bg-slate-100 text-gray-400";
  } else if (type === "letter") {
    classList += "bg-slate-300  ";
  }
  if (highlight === true) {
    classList += "bg-teal-500 font-extrabold animate-pulse ";
  }

  return <div className={`${classList} `}>{children}</div>;
};

export default Box;
