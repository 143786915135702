import PageTitle from "../common/page-title";
import Container from "../layout/container";

const About = () => {
  return (
    <div>
      <PageTitle title="About Polibius" />
      <p className="mb-4">
        Polybius was a Greek historian of the middle Hellenistic period. He is
        noted for his work The Histories, a universal history documenting the
        rise of Rome in the Mediterranean in the third and second centuries BC.
      </p>
      <p>
        Source:{" "}
        <a
          className="text-teal-600"
          href="https://en.wikipedia.org/wiki/Polybius"
          target="_blank"
        >
          Wikipedia
        </a>
      </p>
    </div>
  );
};

export default About;
