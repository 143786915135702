import { Link } from "react-router-dom";
const Logo = () => {
  return (
    <Link to={"/"}>
      <div
        className="grow font-bold text-gray-800 text-2xl flex flex-row gap-2 items-center"
        title="Polybius Square Encryption"
      >
        <span>Polybius</span>{" "}
        <img className="w-5 " src="./assets/square-regular.svg" />
      </div>
    </Link>
  );
};

export default Logo;
