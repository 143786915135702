const CopyToClipboard = (id: string) => {
  const outcome = document.getElementById(id);

  if (!outcome) {
    return;
  }

  const text = outcome.textContent || outcome.innerText;

  const copyContent = async () => {
    try {
      await navigator.clipboard.writeText(text);
      console.log("Content copied to clipboard");
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  copyContent();
};

export default CopyToClipboard;
