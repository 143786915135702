import { ReactNode } from "react";

type Props = {
  type: "submit" | "reset" | "button" | undefined;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  kind: "outlined" | "contained";
  children?: ReactNode;
  disabled?: boolean;
  className?: string;
};
const Button = ({
  children,
  type,
  onClick,
  kind,
  disabled = false,
  className,
}: Props) => {
  const outlined =
    "border border-slate-700 hover:border-slate-700 hover:bg-slate-100 text-slate-700";
  const contained = "bg-slate-700 text-white hover:bg-slate-600";

  let classList = `rounded-lg px-4 py-1 ${
    kind === "outlined" ? outlined : contained
  } ${disabled && " opacity-60"} ${className}`;
  return (
    <button
      type={type}
      disabled={disabled}
      className={classList}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default Button;
