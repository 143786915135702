import "./index.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./layout/layout";
import Home from "./pages/home";
import Encryption from "./pages/encryption";
import Decryption from "./pages/decryption";
import About from "./pages/about";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="encryption" element={<Encryption />} />
            <Route path="decryption" element={<Decryption />} />
            <Route path="about-polybius" element={<About />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
