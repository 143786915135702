import Logo from "../common/logo";
type Props = {
  children: React.ReactNode;
};
const Header = ({ children }: Props) => {
  return (
    <div className="w-full justify-between px-6 h-16 bg-slate-100 flex flex-row items-center">
      <Logo />
      {children}
    </div>
  );
};

export default Header;
